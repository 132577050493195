/* eslint-disable import/no-cycle */
import {
  configureStore,
  ThunkAction,
  Action,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import mobileProjectServerReducer from './mobileProjectServer/mobileProjectServerSlice';
import notificationReducer from './notifications/notificationsSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const reducers = combineReducers({
  mobileProjectServer: mobileProjectServerReducer,
  notifications: notificationReducer,
});
const store = configureStore({
  reducer: persistReducer(persistConfig, reducers),
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType, // eslint-disable-line @typescript-eslint/indent
  RootState, // eslint-disable-line @typescript-eslint/indent
  unknown, // eslint-disable-line @typescript-eslint/indent
  Action<string> // eslint-disable-line @typescript-eslint/indent
>;

export default store;
