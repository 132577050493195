import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';
import mylocIcon from '../../../assets/myloc.png';
import nearlocIcon from '../../../assets/nearloc.png';
import nextnearlocIcon from '../../../assets/nextnearloc.png';
import searchResultIcon from '../../../assets/searchResult.png';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'grid',
    margin: '0px 0px 10px 5px',
    gridTemplateColumns: '1fr 1fr',
  },
  textField: {
    textAlign: 'left',
  },
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
}));

const MapInfo = () => {
  const classes = useStyles();
  const [iconInfo] = useState([{ label: 'Your Position', icon: mylocIcon },
    { label: 'Top 10 near locations', icon: nearlocIcon },
    { label: 'Next top 10 near locations', icon: nextnearlocIcon },
    { label: 'Search result location/s', icon: searchResultIcon }]);

  return (
    <div className={classes.root}>
      {iconInfo.map((iconMarker, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={classes.textField} key={`text-${index}`}>
          <IconButton
            size="small"
          >
            <img src={iconMarker.icon} width="25" height="30" alt="my-location" />
            <Typography variant="subtitle1" style={{ textAlign: 'left' }}>
              {iconMarker.label}
            </Typography>
          </IconButton>
        </div>
      ))}
    </div>
  );
};

export default MapInfo;
