import { createMuiTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

export const colors = {
  rallyGreen: '#1EB980',
  rallyGreenDark: '#045D56',
  rallyPurple: '#B15DFF',
  rallyBlue: '#72DEFF',
  rallyOrange: '#FF6859',
  rallyYellow: '#FFCF44',
};

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: colors.rallyGreen,
    },
    secondary: {
      main: colors.rallyYellow,
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
