/* eslint-disable no-console */
/* eslint-disable max-len */

// creates basic headers
function createHeaders(accessToken?: string): Record<string, string> {
  const result: Record<string, string> = {
    Accept: 'application/json',
  };
  if (accessToken) {
    result.Authorization = `Bearer ${accessToken}`;
  }

  return result;
}

// creates headers for requests with payload
function createPayloadHeaders(accessToken?: string): Record<string, string> {
  let result = createHeaders(accessToken);
  result = {
    ...result,
    'Content-Type': 'application/json',
  };

  return result;
}

// ensure response is success
// if not: tries to parse error and throws exception
type ProblemDetails = {
  type: string,
  title: string,
  status: number,
  detail: string,
  instance: string,
};

async function ensureResponseIsSuccess(response: Response) {
  if (response.ok) {
    return;
  }

  let exceptionMessage = `[${response.status}] ${response.statusText}`;
  // try to parse problem details
  try {
    const problemDetails = await response.json() as ProblemDetails;
    const { status, title, detail } = problemDetails;
    if (status && title && detail) {
      exceptionMessage = `[${status}] ${title}\n${detail}`;
    }
  } catch {
    console.log('>>', exceptionMessage);
  }

  // throw new Error(exceptionMessage);
}

export async function get<ResponseT>(url: string, accessToken?: string): Promise<ResponseT | undefined> {
  const options: RequestInit = {
    method: 'GET',
    headers: createHeaders(accessToken),
  };

  const response = await fetch(url, options);
  await ensureResponseIsSuccess(response);

  let result: ResponseT | undefined;
  try {
    result = await response.json() as ResponseT;
  } catch {
    // console.log('>> error: response not fetched');
  }

  return result;
}

export async function getImage(url: string, accessToken?: string): Promise<any | undefined> {
  const options: RequestInit = {
    method: 'GET',
    headers: createHeaders(accessToken),
  };
  let outside = '';

  return fetch(url, options).then((response:any) => response.blob()).then((images:any) => {
    outside = URL.createObjectURL(images);
    return outside;
  });
}

export async function post<PayloadT, ResponseT>(url: string, payload: PayloadT, accessToken?: string): Promise<ResponseT> {
  const options: RequestInit = {
    method: 'POST',
    headers: createPayloadHeaders(accessToken),
    body: JSON.stringify(payload),
  };

  const response = await fetch(url, options);
  await ensureResponseIsSuccess(response);

  if (response.status === 204) return {} as ResponseT;
  const result = await response.json() as ResponseT;
  return result;
}

export async function postFormData<ResponseT>(url: string, payload: any, accessToken?: string): Promise<ResponseT> {
  const options: RequestInit = {
    method: 'POST',
    headers: { Accept: 'application/json', Authorization: `Bearer ${accessToken}` },
    body: payload,
  };

  const response = await fetch(url, options);
  await ensureResponseIsSuccess(response);

  if (response.status === 204) return {} as ResponseT;
  if (response.status === 400) return {} as ResponseT;
  const result = await response.json() as ResponseT;
  return result;
}

export async function put<PayloadT, ResponseT>(url: string, payload: PayloadT, accessToken?: string): Promise<ResponseT> {
  const options: RequestInit = {
    method: 'PUT',
    headers: createPayloadHeaders(accessToken),
    body: JSON.stringify(payload),
  };

  const response = await fetch(url, options);
  await ensureResponseIsSuccess(response);
  const result = await response.json() as ResponseT;
  return result;
}

export async function del(url: string, accessToken?: string) {
  const options: RequestInit = {
    method: 'DELETE',
    headers: createHeaders(accessToken),
  };

  const response = await fetch(url, options);
  await ensureResponseIsSuccess(response);
}

export async function delWithPayloadAndResponse<PayloadT, ResponseT>(url: string, payload: PayloadT, accessToken?: string) {
  const options: RequestInit = {
    method: 'DELETE',
    headers: createHeaders(accessToken),
    body: JSON.stringify(payload),
  };

  const response = await fetch(url, options);
  await ensureResponseIsSuccess(response);

  const result = await response.json() as ResponseT;
  return result;
}

export async function delWithResponse<ResponseT>(url: string, accessToken?: string): Promise<ResponseT> {
  const options: RequestInit = {
    method: 'DELETE',
    headers: createHeaders(accessToken),
  };

  const response = await fetch(url, options);
  await ensureResponseIsSuccess(response);

  const result = await response.json() as ResponseT;
  return result;
}
