import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import L from 'leaflet';
import { useHistory } from 'react-router-dom';
import {
  selectFleetFlat, selectCustomerId, selectPosition, setCustomerId,
  setPosition, selectCustomerLocationCode, setCustomerLocationCode,
} from 'store/mobileProjectServer/mobileProjectServerSlice';
import { LocationFlat, FleetFlat } from 'store/mobileProjectServer/types';
import mylocIcon from '../../../assets/myloc.png';
import nearlocIcon from '../../../assets/nearloc.png';
import nextnearlocIcon from '../../../assets/nextnearloc.png';
import getNearLocations from './NearLocations';
import { Location, Marker } from '../../types/select.type';
import MapInfo from './MapInfo';

const style = {
  marginTop: '10px',
  width: '100%',
  height: '300px',
};

const searchIcon = L.icon({
  iconSize: [40, 48],
  iconAnchor: [10, 41],
  tooltipAnchor: [15, -27],
  iconUrl: mylocIcon,
});

const nearIcon = L.icon({
  iconSize: [40, 48],
  iconAnchor: [10, 41],
  tooltipAnchor: [15, -27],
  iconUrl: nearlocIcon,
});

const nextnearIcon = L.icon({
  iconSize: [40, 48],
  iconAnchor: [10, 41],
  tooltipAnchor: [15, -27],
  iconUrl: nextnearlocIcon,
});

const Map = ({ markerLocations }: any): any => {
  const myLocation:any = useSelector(selectPosition);
  const locationCode:any = useSelector(selectCustomerLocationCode);
  const fleetFlat: FleetFlat = useSelector(selectFleetFlat);
  const dispatch = useDispatch();
  const [mapMarkers, setMapMarkers]: any[] = useState<any[]>([]);
  const customerId: any = useSelector(selectCustomerId);
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const [currentCustomer, setCurrentCustomer] = useState(undefined);
  const [currentLocationCode, setCurrentLocationCode] = useState(undefined);
  // Oslo: [59.911491, 10.757933]
  const defaultLocation = { lat: 59.911491, lng: 10.757933 };
  const mapRef: any = useRef(null);
  const tileLayerUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
  const history = useHistory();

  const isMapAvailable = () => mapRef.current !== undefined && mapRef.current !== null;

  const removeMarker = () => {
    for (let i = 0; i < mapMarkers.length; i += 1) {
      mapMarkers.map((mark: any) => {
        mapRef.current.removeLayer(mark);
        return true;
      });
    }
  };

  const goToLocationPage = () => {
    history.push('/location');
  };

  const handleMarkerClick = (customerCode: number, locationId: number) => {
    goToLocationPage();
    dispatch(setCustomerId(customerCode));
    dispatch(setCustomerLocationCode(locationId));
  };

  const handleNearMarkerClick = (customerCode: number, locationId: number) => {
    dispatch(setCustomerId(customerCode));
    dispatch(setCustomerLocationCode(locationId));
    goToLocationPage();
  };

  const getCustomerCode = (lCode:number) => {
    const customerCode = fleetFlat.locations.filter((loc:LocationFlat) => (loc.code === lCode));
    if (customerCode[0] === undefined) return 0;
    return customerCode[0].customerCode;
  };

  const markMyLocation = () => {
    const nearLocations:LocationFlat[] = getNearLocations(fleetFlat, myLocation);
    const mapNearLocations:any[] = [];
    navigator.geolocation.getCurrentPosition((position) => {
      const tempLocation:Location = { lat: Number(position.coords.latitude), lng: Number(position.coords.longitude) };
      const myloc = L.marker(
        {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
        { icon: searchIcon },
      ).bindTooltip(`My location: ${tempLocation === defaultLocation ? 'default Location' : 'GPS location'}`);
      myloc.addTo(mapRef.current);

      dispatch(setPosition({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }));
    });
    for (let i = 0; i < 20; i += 1) {
      mapNearLocations[i] = L.marker(
        {
          lat: Number(nearLocations[i].latitude),
          lng: Number(nearLocations[i].longitude),
        },
        { icon: i <= 11 ? nearIcon : nextnearIcon },
      ).bindTooltip(`${nearLocations[i].name}`);

      mapNearLocations[i].on('click', () => {
        handleNearMarkerClick(getCustomerCode(nearLocations[i].code), nearLocations[i].code);
      });
      mapNearLocations[i].addTo(mapRef.current);
    }
  };

  const toRadian = (degree: any) => (degree * Math.PI) / 180;
  const getDistance = (origin: any, destination: any) => {
    if (origin[0] !== undefined && origin[1] !== undefined
      && destination[1] !== undefined && destination[1] !== undefined) {
      const lon1 = toRadian(origin[1]);
      const lat1 = toRadian(origin[0]);
      const lon2 = toRadian(destination[1]);
      const lat2 = toRadian(destination[0]);

      const deltaLat = lat2 - lat1;
      const deltaLon = lon2 - lon1;

      const a = (Math.sin(deltaLat / 2) ** 2)
      + Math.cos(lat1) * Math.cos(lat2) * (Math.sin(deltaLon / 2) ** 2);
      const c = 2 * Math.asin(Math.sqrt(a));
      const EARTH_RADIUS = 6371;
      return c * EARTH_RADIUS * 1000;
    }
    return 0;
  };

  const filterMarkers = (newMarkers:Marker[]):Marker[] => {
    const tempMarkers:Marker[] = newMarkers;
    const filteredMarkers:Marker[] = [];

    for (let i = 0; i < newMarkers.length; i += 1) {
      let counter = i;
      for (let k = 0; k < newMarkers.length; k += 1) {
        if ((tempMarkers[k].lat === newMarkers[i].lat) && (tempMarkers[k].lng === newMarkers[i].lng)
        && (tempMarkers[k].name !== newMarkers[i].name)) {
          filteredMarkers.push(
            {
              lat: tempMarkers[k].lat,
              lng: tempMarkers[k].lng,
              customerCode: tempMarkers[k].customerCode,
              locationId: tempMarkers[k].customerCode,
              name: `${tempMarkers[k].name}, ${newMarkers[i].name} `,
            },
          );
          counter = i + 1;
        }
      }
      if (counter === i) { filteredMarkers.push(newMarkers[i]); }
    }
    return filteredMarkers;
  };

  const updateMarkers = (newMarkers: Marker[]) => {
    const markers: any[] = [];
    if (isMapAvailable()) {
      mapRef.current.eachLayer((layer: any) => {
        // eslint-disable-next-line no-underscore-dangle
        if (layer._url !== tileLayerUrl) mapRef.current.removeLayer(layer);
      });
      markMyLocation();
    }
    if (newMarkers.length > 0) {
      filterMarkers(newMarkers).map((mark: Marker) => {
        if (mark.lat !== undefined && mark.lng !== undefined) {
          const marker = L.marker({
            lat: mark.lat === undefined ? 0 : mark.lat,
            lng: mark.lng === undefined ? 0 : mark.lng,
          }).bindTooltip(
            `${mark.name}, ${(
              getDistance(
                [myLocation.lat === undefined ? 0 : myLocation.lat, myLocation.lng === undefined ? 0 : myLocation.lng],
                [mark.lat === undefined ? 0 : mark.lat, mark.lng === undefined ? 0 : mark.lng],
              ) / 1000
            ).toFixed(2)} km.`,
          );
          let newMyloc = {};
          navigator.geolocation.getCurrentPosition((position) => {
            newMyloc = L.marker(
              {
                lat: Number(position.coords.latitude),
                lng: Number(position.coords.longitude),
              }, { icon: searchIcon },
            ).bindTooltip('My Location');
          });
          markers.push(newMyloc);
          marker.on('click', () => {
            handleMarkerClick(getCustomerCode(mark.locationId), mark.locationId);
          });
          if (isMapAvailable()) marker.addTo(mapRef.current);
        }
        return true;
      });
    }
    setMapMarkers(markers);
  };

  useEffect(() => {
    mapRef.current = L.map('map', {
      zoom: 5,
      center: [myLocation.lat, myLocation.lng],
      layers: [
        L.tileLayer(tileLayerUrl, {
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        }),
      ],
    });
    markMyLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (customerId === null) {
    removeMarker();
  } else if (currentCustomer !== customerId) {
    setCurrentCustomer(() => {
      updateMarkers(markerLocations);
      return customerId;
    });
  } else if (currentLocationCode !== locationCode) {
    setCurrentLocationCode(() => {
      let locationName: any = fleetFlat.locations.filter((loc) => (loc.code === locationCode));
      locationName = locationName[0] !== undefined ? locationName[0].name : null;
      if (locationName !== null) {
        updateMarkers(markerLocations.filter(((marker:Marker) => (marker.name === locationName))));
      } else { updateMarkers(markerLocations); }
      return locationCode;
    });
  }

  return <div id="map" style={style} />;
};

const LocationMapMemo = React.memo(Map);

const MapContainer = () => {
  const fleetFlat: FleetFlat = useSelector(selectFleetFlat);
  const customerId: number | null = useSelector(selectCustomerId);
  const [mapMarks, setMapMarks] = React.useState<Marker[]>([]);
  const [fleetFetched, setFleetFetched] = useState<Boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<number | null>(null);

  const loadMarkContent = (currentCustomer: any) => {
    const currentMarks: Marker[] = [];
    fleetFlat.locations.map((location: LocationFlat) => {
      if (location.customerCode === currentCustomer) {
        currentMarks.push({
          lat: Number(location.latitude),
          lng: Number(location.longitude),
          customerCode: location.customerCode,
          locationId: location.code,
          name: location.name,
        });
      }
      return true;
    });
    setMapMarks(currentMarks);
    setFleetFetched(true);
  };

  if (!fleetFetched && fleetFlat.customers.length > 0) {
    loadMarkContent(null);
  } else if (fleetFetched && selectedCustomer !== customerId) {
    setSelectedCustomer(() => {
      loadMarkContent(customerId);
      return customerId;
    });
  }

  return (
    <>
      <LocationMapMemo markerLocations={mapMarks} />
      <MapInfo />
    </>
  );
};

export default MapContainer;
