/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Card, CardContent, Typography } from '@material-ui/core';
import { NodeList, Ticket, CardType } from 'components/types/node.types';
import { NodeFlat } from 'store/mobileProjectServer/types';
import { setCustomerNodeCode } from '../../../store/mobileProjectServer/mobileProjectServerSlice';
import { getTicketStatus } from '../../../api/mobileProjectServer/api';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    marginTop: '5px',
    marginLeft: '5px',
    marginRight: '5px',
  },
  location: {
    margin: '10px 0 10px -10px',
    gridTemplateRows: 'repeat(1fr)',
  },
  node: {
    borderRadius: '12px',
    margin: '0 0 6px',
    width: '100%',
    boxShadow: '0 5px 8px 0 rgba(0, 0, 0, 0.3)',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  nodes: {
    border: '7px groove #4243',
    borderRadius: '15px 15px 15px 15px',
  },
}));

const Cards = (props: CardType) => {
  const { authToken, locationNodes } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [places, setPlaces] = useState<any[][]>([]);
  const [tickets, setTickets] = useState<Ticket[]>([]);

  const groupByPlaceName = (collection: any, attrib: any) => {
    let i = 0;
    let val;
    let index;
    const values = [];
    const result = [];
    for (; i < collection.length; i += 1) {
      val = collection[i][attrib];
      index = values.indexOf(val);
      if (index > -1) result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
    return result;
  };

  useEffect(() => {
    setPlaces(groupByPlaceName(locationNodes, 'placeName'));
    const getStatus = () => {
      getTicketStatus(authToken).then((res:any) => {
        setTickets([...tickets, res]);
      });
    };
    getStatus();
  }, []);

  const handleClick = (serialNo: string) => {
    history.push('/node');
    dispatch(setCustomerNodeCode(serialNo));
  };

  const getOpenCloseCount = (serial:string) => {
    const openCloseCount:{ open:number, close:number } = { open: 0, close: 0 };
    if (serial) {
      const ticketList: any = tickets[0];
      ticketList.forEach((ticket:Ticket) => {
        if (ticket.equipmentSerialNo === serial) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          ticket.isOpen ? openCloseCount.open += 1 : openCloseCount.close += 1;
        }
      });
    }
    return `Open: ${openCloseCount.open}, Closed: ${openCloseCount.close}`;
  };

  return (
    <div>
      {places.map((nodes: NodeFlat[]) => (
        <div key={nodes[0].placeName}>
          <div style={{ margin: '4px 4px 4px 8px' }}>
            {nodes[0].placeName}
          </div>
          <div className={classes.nodes}>
            {nodes.map((node: any) => (
              <Card
                key={node.serialNo}
                onClick={() => handleClick(node.serialNo)}
                variant="outlined"
              >
                <Typography className={classes.pos} color="textSecondary">
                  Serial no.
                  {' '}
                  {node.serialNo}
                </Typography>
                <Typography
                  variant="body2"
                  component="p"
                  color="textSecondary"
                >
                  Tickets:
                  {' '}
                  {tickets.length > 0 && getOpenCloseCount(node.serialNo)}
                </Typography>
              </Card>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const NodesList = (props: NodeList) => {
  const {
    location, customer, tickets, locationNodes, authToken,
  } = props;
  const classes = useStyles();
  return (
    <Card className={classes.node}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {customer}
          {' '}
          /
          {location}
        </Typography>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {}
        </Typography>
        <Cards
          locationNodes={locationNodes}
          tickets={tickets}
          authToken={authToken}
        />
      </CardContent>
    </Card>
  );
};

export default NodesList;
