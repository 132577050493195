import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectFleetFlat,
  selectFleetFetchStatus,
  selectCustomerId,
  setCustomerLocationCode,
} from 'store/mobileProjectServer/mobileProjectServerSlice';
import { FleetFlat, LocationFlat } from 'store/mobileProjectServer/types';
import { StoreActionStatus as Status } from 'store/storeTypes';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '20%',
    marginLeft: '5px',
    marginRight: '5px',
  },
  progressCircleContainer: {
    height: 'calc(100% - 64px)',
  },
  formControl: {
    margin: 1,
    minWidth: 120,
  },
  select: {
    marginTop: 2,
    minWidth: 6,
  },
}));

const Location = () => {
  const [customerLocation, setCustomerLocation] = useState('');
  const customerId: number | null = useSelector(selectCustomerId);
  const fleetFlat: FleetFlat = useSelector(selectFleetFlat);
  const fleetFetchStatus: Status = useSelector(selectFleetFetchStatus);
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCustomerLocation(event.target.value as string);
    dispatch(setCustomerLocationCode(Number(event.target.value)));
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="customer-location">Location</InputLabel>
        <Select
          native
          value={customerLocation}
          onChange={handleChange}
          inputProps={{
            name: 'locationName',
            id: 'location-name',
          }}
          className={classes.select}
        >
          <option aria-label="None" value="" />
          {fleetFetchStatus === Status.Success
            && fleetFlat.locations.map(
              (location: LocationFlat) => location.customerCode === customerId && (
              <option value={location.code} key={location.code}>
                {location.name}
              </option>
              ),
            )}
        </Select>
      </FormControl>
    </div>
  );
};

export default Location;
