import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { makeStyles } from '@material-ui/core/styles';
import { Select, FormControl, InputLabel } from '@material-ui/core';
import {
  selectFleetFlat,
  fetchFleetFlat,
  selectFleetFetchStatus,
  setCustomerId,
} from 'store/mobileProjectServer/mobileProjectServerSlice';
import { FleetFlat, CustomerFlat } from 'store/mobileProjectServer/types';
import { StoreActionStatus as Status } from 'store/storeTypes';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '20%',
    marginTop: '5px',
    marginLeft: '5px',
    marginRight: '5px',
  },
  formControl: {
    margin: 1,
    minWidth: 120,
  },
  select: {
    marginTop: 2,
    minWidth: 6,
  },
}));

const Customer = () => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const fleetFlat: FleetFlat = useSelector(selectFleetFlat);
  const fleetFetchStatus: Status = useSelector(selectFleetFetchStatus);

  useEffect(() => {
    const getFleetFlat = async () => {
      dispatch(fetchFleetFlat(await getAccessTokenSilently()));
    };
    getFleetFlat();
  }, [dispatch, getAccessTokenSilently]);

  const [customerName, setCustomerName] = React.useState('');
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCustomerName(event.target.value as string);
    dispatch(setCustomerId(Number(event.target.value)));
  };

  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="customer-name">Customer</InputLabel>
        <Select
          native
          value={customerName}
          onChange={handleChange}
          inputProps={{
            name: 'customerName',
            id: 'customer-name',
          }}
          className={classes.select}
        >
          <option aria-label="None" value="" />
          {fleetFetchStatus !== Status.Processing
            && fleetFlat.customers.map((customer: CustomerFlat) => (
              <option value={customer.code} key={customer.code}>
                {customer.name}
              </option>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default Customer;
