/* eslint-disable max-len */
/* eslint-disable  @typescript-eslint/no-unused-expressions */
/* eslint-disable  react/no-array-index-key */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-globals */

import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Camera, { IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import {
  Button, Modal, Fade, InputLabel, TextareaAutosize, Backdrop, Card, Box,
} from '@material-ui/core';

// Storage:
import { postTicketStatus } from '../../../api/mobileProjectServer/api';

const useStyles = makeStyles((theme: Theme) => ({
  registerBtn: {
    textAlign: 'center',
    maxWidth: '450px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  imgClose: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    border: '1px solid red',
    display: 'block',
    background: '#FFF',
    zIndex: 2147483647,
  },
}));

const RegisterTicket = ({ nodeInfo, setRegister }:any) => {
  const [existingPic, setExistingPic] = useState<any[]>([]);
  const [dataSent, setDataSent] = useState<any>({
    EquipmentSerialNo: nodeInfo.nodeId, Summary: '', Description: '', Attachments: [],
  });
  const [shotNumber, setShotNumber] = useState<number>(0);

  const [addPhoto, setPhoto] = useState<boolean>(false);
  const classes = useStyles();

  const handleAllPhotos = (img:any) => {
    if (img !== null) {
      setExistingPic([...existingPic, img]);
      setDataSent({ ...dataSent, Attachments: [...existingPic, img] });
    }
  };

  const b64toBlob = (dataURI:any) => {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpg' });
  };

  const handleFile = (blob:any, fileName:any) => new File([blob], fileName);

  const handleScrImages = (img:any) => {
    handleAllPhotos(handleFile(b64toBlob(img), `shot-${shotNumber}.jpg`));
    setShotNumber(Number(shotNumber) + 1);
  };

  const handleCapture = (dataUri :any) => {
    if (dataUri !== undefined && dataUri !== ' data:,') {
      handleScrImages(dataUri);
    }
    setPhoto(false);
  };

  const handlePhotoImports = (e:any) => {
    const { files } = e.target;
    const filesArr = Array.prototype.slice.call(files);
    setExistingPic([...existingPic, ...filesArr]);
    setDataSent({ ...dataSent, Attachments: [...existingPic, ...filesArr] });
  };

  const handleRemoveImport = (f:any) => {
    const currentArray:any[] = existingPic.filter((x) => x !== f);
    setExistingPic([...currentArray]);
    setDataSent({ ...dataSent, Attachments: [...currentArray] });
  };

  const handleUpload = (data:any) => {
    if (dataSent.equipmentSerialNo !== '') {
      nodeInfo.accessToken.then((token:any) => {
        postTicketStatus(token, data).then(() => {
          setRegister(false);
          location.reload();
        });
      });
    }
  };

  const handleSubmit = (e:any) => {
    e.preventDefault();
    const data = new FormData();
    Object.keys(dataSent).forEach((key:any) => {
      if (key !== 'Attachments') data.append(key, dataSent[key]);
      else {
        for (let i = 0; i < dataSent.Attachments.length; i += 1) {
          data.append('AttachmentFiles', dataSent.Attachments[i], dataSent.Attachments[i].name);
        }
      }
    });
    handleUpload(data);
  };

  return (
    <Card variant="outlined">
      <Box fontSize={18} m={1}>
        Registering a ticket:
      </Box>
      <form onSubmit={handleSubmit} style={{ textAlign: 'center', padding: '10px 2px 10px 2px' }}>
        <InputLabel htmlFor="Summary">Summary</InputLabel>
        <TextareaAutosize id="Summary" name="Summary" required defaultValue={dataSent.Summary} rowsMin={2} style={{ width: '80%' }} onChange={(e) => { setDataSent({ ...dataSent, Summary: e.target.value }); }} />
        <InputLabel htmlFor="Description">Description</InputLabel>
        <TextareaAutosize id="Description" name="Description" required defaultValue={dataSent.Description} rowsMin={4} style={{ width: '80%' }} onChange={(e) => { setDataSent({ ...dataSent, Description: e.target.value }); }} />
        <div>
          <Box fontSize={18} m={1}>
            Add Photo/s
          </Box>
          <br />
          <Button variant="contained" color="primary" onClick={() => { setPhoto(!addPhoto); }} style={{ width: '80%' }}>
            {addPhoto ? 'Close Camera' : 'Take a Picture'}
          </Button>
          <br />
          <div className="custom-file-upload" style={{ marginTop: '10px' }}>
            <input type="file" multiple onChange={handlePhotoImports} />
            <div>
              {existingPic.map((x, i:number) => <div key={x.name} tabIndex={i + 2} role="link" className="file-preview" onKeyPress={() => handleRemoveImport(x)} onClick={() => handleRemoveImport(x)}>{x.name}</div>)}
            </div>
          </div>
          {addPhoto && (
            <div style={{
              position: 'absolute', top: 0, right: 0, bottom: 0, left: 0,
            }}
            >
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-Description"
                className={classes.modal}
                open={addPhoto}
                onClose={() => setPhoto(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={addPhoto}>
                  <div className={classes.paper}>
                    <button className={classes.imgClose} type="button" onClick={() => { setPhoto(false); }}> X </button>
                    <Camera
                      onTakePhoto={(dataUri:any) => { handleCapture(dataUri); }}
                      imageCompression={0.97}
                      isMaxResolution
                      imageType={IMAGE_TYPES.JPG}
                      isImageMirror={false}
                      isSilentMode={false}
                      isDisplayStartCameraError
                      isFullscreen={addPhoto}
                      sizeFactor={1}
                    />
                  </div>
                </Fade>
              </Modal>
            </div>
          )}
        </div>
        <div style={{ marginLeft: '10px', display: 'flex' }}>
          {existingPic.map((imgSrc:any, ind:any) => {
            const imgSrch = URL.createObjectURL(imgSrc);
            return <div key={ind}><img style={{ width: '70px', height: '70px' }} src={imgSrch} alt="image" /></div>;
          })}
        </div>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          name="SAVE TICKET"
          value="Submit"
          style={{
            backgroundColor: 'green', width: '80%', marginTop: '10px', height: '35px',
          }}
        >
          Save
        </Button>
      </form>
    </Card>
  );
};

export default RegisterTicket;
