/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'store/store';
import { FailurePayload } from 'api/utility/types';
import { StoreActionStatus as Status } from 'store/storeTypes';
import { getFleetFlat } from 'api/mobileProjectServer/api';
import { buildFleetFlatFromFlat } from './helper';
import { FleetFlat } from './types';
import { fleetFlatDefault } from './defaults';

type PositionType = {
  lat: number,
  lng: number,
};

type FleetState = {
  fleetFlat: FleetFlat,
  fleetFetchStatus: Status,
  customerId: number | null,
  customerLocationCode: number | null,
  customerNodeCode: string | null,
  position: PositionType | null,
  error: string | null,
};

// Oslo: [59.911491, 10.757933]
const OsloLocation = { lat: 59.911491, lng: 10.757933 };

const initialState: FleetState = {
  fleetFlat: fleetFlatDefault,
  fleetFetchStatus: Status.Init,
  customerId: null,
  customerLocationCode: null,
  customerNodeCode: null,
  position: OsloLocation,
  error: null,
};

export const mobileProjectServer = createSlice({
  name: 'mobileProjectServer',
  initialState,
  reducers: {
    setFleetFetchStatus: (state, action: PayloadAction<Status>) => { state.fleetFetchStatus = action.payload; },
    setFleetFlat: (state, action: PayloadAction<FleetFlat>) => { state.fleetFlat = action.payload; },
    setCustomerId: (state, action: PayloadAction<number | null>) => { state.customerId = action.payload; },
    setCustomerLocationCode: (state, action: PayloadAction<number | null>) => { state.customerLocationCode = action.payload; },
    setCustomerNodeCode: (state, action: PayloadAction<string | null>) => { state.customerNodeCode = action.payload; },
    setPosition: (state, action: PayloadAction<PositionType | null>) => { state.position = action.payload; },
    setError: (state, action: PayloadAction<FailurePayload | null>) => {
      state.error = action.payload ? action.payload.text : null;
    },
  },
});

// actions
export const {
  setFleetFetchStatus,
  setFleetFlat,
  setCustomerId,
  setCustomerLocationCode,
  setCustomerNodeCode,
  setPosition,
  setError,
} = mobileProjectServer.actions;

// selectors
export const selectFleetFlat = (state: RootState) => ({ ...state.mobileProjectServer.fleetFlat });
export const selectFleetFetchStatus = (state: RootState) => state.mobileProjectServer.fleetFetchStatus;
export const selectError = (state: RootState) => state.mobileProjectServer.error;
export const selectCustomerId = (state: RootState) => (state.mobileProjectServer.customerId);
export const selectCustomerLocationCode = (state: RootState) => (state.mobileProjectServer.customerLocationCode);
export const selectCustomerNodeCode = (state: RootState) => (state.mobileProjectServer.customerNodeCode);
export const selectPosition = (state: RootState) => (state.mobileProjectServer.position);

// action creators
export const fetchFleetFlat = (accessToken: string): AppThunk => async (dispatch) => {
  dispatch(setFleetFetchStatus(Status.Processing));
  try {
    const response = await getFleetFlat(accessToken);
    dispatch(setFleetFlat(buildFleetFlatFromFlat(response)));
    dispatch(setFleetFetchStatus(Status.Success));
    dispatch(setCustomerId(null));
    dispatch(setCustomerLocationCode(null));
    dispatch(setCustomerNodeCode(null));
    dispatch(setPosition(OsloLocation));
    dispatch(setError(null));
  } catch (err) {
    dispatch(setFleetFetchStatus(Status.Error));
    dispatch(setError({
      notify: true,
      title: 'Failed to load fleet',
      text: typeof err.message === 'string' ? err.message : 'Unknown error',
    }));
  }
};

export default mobileProjectServer.reducer;
