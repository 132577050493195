import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

import {
  selectCustomerId,
  selectCustomerLocationCode,
} from 'store/mobileProjectServer/mobileProjectServerSlice';

import Customer from './Customer';
import LocationMap from './Map';
import Location from './Location';
import SearchBar from './SearchBar';

const fieldName = {
  goToLocationPageButton: 'Location Page',
};

const useStyles = makeStyles((theme) => ({
  menu: {
    width: '80%',
    position: 'relative',
    display: 'inline-grid',
    gridTemplateRows: '1fr 1fr 1fr 0.5fr',
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {},
  },
  button: {
    height: '35px',
    width: '55%',
    maxWidth: '170px',
  },
}));

const SelectPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const customerLocationCode = useSelector(selectCustomerLocationCode);
  const customerId = useSelector(selectCustomerId);
  const gotoLocationPage = () => {
    history.push('/location');
  };

  const isLocationPageButtonDisabled = () => (customerLocationCode === null ? customerId === null : false);
  return (
    <div>
      <div className={classes.menu}>
        <SearchBar />
        <Customer />
        <Location />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={gotoLocationPage}
          disabled={isLocationPageButtonDisabled()}
        >
          {fieldName.goToLocationPageButton}
        </Button>
      </div>
      <LocationMap />
    </div>
  );
};

export default SelectPage;
