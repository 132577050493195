import {
  get, put, postFormData, getImage,
} from 'api/utility/communication';
import AppSettings from 'AppSettings';
import { FleetFlatResponse } from './responseTypes';

const BASE_URL = AppSettings.apis.mobileProjectServer.url;

// eslint-disable-next-line import/prefer-default-export
export async function getFleetFlat(
  accessToken: string,
): Promise<FleetFlatResponse> {
  const response = await get<FleetFlatResponse>(
    `${BASE_URL}/fleet`,
    accessToken,
  );
  if (!response) throw new Error('Response is empty.');
  return response;
}

export async function getNodeStatus(
  nodeId: string,
  accessToken: string,
): Promise<any> {
  let response = await get<any>(
    `${BASE_URL}/Navigator/nodes/${nodeId}/status`,
    accessToken,
  );
  if (!response) {
    // throw new Error('Response is empty.');
    response = {};
  }
  return response;
}

export async function getTicketStatus(
  accessToken: string,
): Promise<any> {
  const response = await get<any>(
    `${BASE_URL}/Tickets`,
    accessToken,
  );
  if (!response) throw new Error('Response is empty.');
  return response;
}

export async function getTicketStatusById(
  accessToken: string,
  id:number,
): Promise<any> {
  const response = await get<any>(
    `${BASE_URL}/Tickets/${id}`,
    accessToken,
  );
  if (!response) throw new Error('Response is empty.');
  return response;
}

export async function getAttachmentById(
  accessToken: string,
  id:number,
): Promise<any> {
  const response = getImage(
    `${BASE_URL}/Tickets/AttachmentFiles/${id}`,
    accessToken,
  );
  return response;
}

export async function toggleTicketStatus(
  accessToken: string,
  payload:any,
  id:number,
): Promise<any> {
  const response = await put<any, any>(
    `${BASE_URL}/Tickets/${id}`,
    payload,
    accessToken,
  );
  if (!response) throw new Error('Response is empty.');
  return response;
}

export async function postTicketStatus(
  accessToken: string,
  params:any,
): Promise<any> {
  const response = await postFormData<any>(
    `${BASE_URL}/Tickets`,
    params,
    accessToken,
  );

  if (!response) throw new Error('Response is empty.');
  return response;
}
