import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Notifier from 'components/partials/Notifier/Notifier';
import NavBar from 'components/layout/NavBar/NavBar';
import SignIn from 'components/pages/SignIn/SignIn';
import LocationPage from 'components/pages/LocationPage';
import NodePage from 'components/pages/NodePage';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import NotFound from 'components/pages/NotFound/NotFound';
import SelectPage from 'components/pages/SelectPage';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'lightblue',
    background: '#212121',
    display: 'flex',
  },
  selectSection: {
    marginLeft: '2px',
    borderColor: 'yellowgreen',
    borderBottom: '5px solid rgba(28,110,164,0.15)',
    borderRadius: '24px 27px 40px 2px',
  },
  menu: {
    position: 'relative',
    display: 'inline-grid',
    gridTemplateRows: '1fr 1fr 1fr',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {},
  },
}));

const App = () => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();
  return (
    <>
      <Notifier />
      {!isAuthenticated ? (
        <SignIn />
      ) : (
        <Router>
          <NavBar />
          <Container className={classes.selectSection}>
            <Switch>
              <Route path="/" exact>
                <Redirect to="/select" />
              </Route>
              <Route path="/select" exact>
                <SelectPage />
              </Route>
              <Route
                path="/location"
                render={() => <LocationPage />}
              />
              <Route
                path="/node"
                render={() => <NodePage />}
              />
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </Container>
        </Router>
      )}
    </>
  );
};

export default App;
