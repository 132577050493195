import {
  FleetFlat,
  CustomerFlat,
  LocationFlat,
  PlaceFlat,
  PenCabinetFlat,
  PenCabinetPortFlat,
  FeedbargeCabinetFlat,
  CableSpanFlat,
  NodeFlat,
  PlaceType,
} from './types';

export const fleetFlatDefault:FleetFlat = {
  customers: [],
  locations: [],
  places: [],
  cableSpans: [],
  feedbargeCabinets: [],
  penCabinets: [],
  penCabinetPorts: [],
  nodes: [],
};
export const customerFlatDefault:CustomerFlat = {
  code: 0,
  name: '',
  hqAddress: '',
  hqLatitude: null,
  hqLongitude: null,
  isActive: false,
  createTMS: '',
  updateTMS: '',
  createBy: '',
  updateBy: '',
};
export const locationFlatDefault:LocationFlat = {
  code: 0,
  name: '',
  isActive: false,
  country: '',
  aquaCultureSiteNumber: '',
  latitude: '',
  longitude: '',
  customerCode: 0,
  customerName: '',
  customerIsActive: false,
  createTMS: '',
  updateTMS: '',
  createBy: '',
  updateBy: '',
};
export const placeFlatDefault:PlaceFlat = {
  code: 0,
  name: '',
  placeTypeId: PlaceType.Other,
  placeTypeName: 'Other',
  isActive: false,
  customerCode: 0,
  customerName: '',
  customerIsActive: false,
  locationCode: 0,
  locationName: '',
  locationIsActive: false,
  createTMS: '',
  updateTMS: '',
  createBy: '',
  updateBy: '',
};
export const cableSpanFlatDefault:CableSpanFlat = {
  code: 0,
  label: '',
  customerCode: 0,
  customerName: '',
  customerIsActive: false,
  locationCode: 0,
  locationName: '',
  locationIsActive: false,
  placeCode: 0,
  placeName: '',
  placeTypeId: PlaceType.Other,
  placeTypeName: 'Other',
  placeIsActive: false,
  fromTMS: '',
  createTMS: '',
  updateTMS: '',
  createBy: '',
  updateBy: '',
};
export const feedbargeCabinetFlatDefault:FeedbargeCabinetFlat = {
  serialNo: '',
  partName: 'Feedbarge Cabinet',
  partNumber: 'XXXXXX-XXX',
  partRevision: '-',
  customerCode: 0,
  customerName: '',
  customerIsActive: false,
  locationCode: 0,
  locationName: '',
  locationIsActive: false,
  placeCode: 0,
  placeName: '',
  placeTypeId: PlaceType.Other,
  placeTypeName: 'Other',
  fromTMS: '',
  createTMS: '',
  updateTMS: '',
  createBy: '',
  updateBy: '',
};
export const penCabinetFlatDefault:PenCabinetFlat = {
  serialNo: '',
  partName: 'Pen Cabinet',
  partNumber: 'XXXXXX-XXX',
  partRevision: '-',
  customerCode: 0,
  customerName: '',
  customerIsActive: false,
  locationCode: 0,
  locationName: '',
  locationIsActive: false,
  placeCode: 0,
  placeName: '',
  placeTypeId: PlaceType.Other,
  placeTypeName: 'Other',
  fromTMS: '',
  createTMS: '',
  updateTMS: '',
  createBy: '',
  updateBy: '',
};
export const penCabinetPortFlatDefault:PenCabinetPortFlat = {
  cabinetSerialNo: '',
  cabinetPartName: 'Pen Cabinet',
  cabinetPartNumber: 'XXXXXX-XXX',
  cabinetPartRevision: '-',
  port: 0,
  customerCode: 0,
  customerName: '',
  customerIsActive: false,
  locationCode: 0,
  locationName: '',
  locationIsActive: false,
  placeCode: 0,
  placeName: '',
  placeTypeId: PlaceType.Other,
  placeTypeName: 'Other',
  cableSpanCode: null,
  cableSpanLabel: null,
  toTMS: '',
  fromTMS: '',
  createTMS: '',
  updateTMS: '',
  createBy: '',
  updateBy: '',
};
export const nodeFlatDefault: NodeFlat = {
  serialNo: '',
  partName: 'Laser Node',
  partNumber: 'XXXXXX-XXX',
  partRevision: '-',
  customerCode: 0,
  customerName: '',
  customerIsActive: false,
  locationCode: 0,
  locationName: '',
  locationIsActive: false,
  placeCode: 0,
  placeName: '',
  placeTypeId: PlaceType.Other,
  placeTypeName: 'Other',
  cableSpanCode: 0,
  cableSpanLabel: '',
  fromTMS: '',
  createTMS: '',
  updateTMS: '',
  createBy: '',
  updateBy: '',
};
