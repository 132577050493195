import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';

import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';

import { enqueueNotification } from 'store/notifications/notificationsSlice';

import styles from './AccessTokenDialog.module.css';

type AccessKeyDialogProps = {
  open: boolean,
  onClose: () => void,
};

const AccessTokenDialog = (props: AccessKeyDialogProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  const { open, onClose } = props;

  // refreshing access token when dialog is opened
  // clearing when dialog is closed
  const [accessToken, setAccessToken] = useState('');
  useEffect(() => {
    const refreshAccessToken = async () => {
      setAccessToken(await getAccessTokenSilently());
    };
    if (open) {
      refreshAccessToken();
    } else {
      setAccessToken('');
    }
  }, [getAccessTokenSilently, open]);

  const inputRef = useRef<null | HTMLInputElement>(null);

  const handleCopy = () => {
    if (!inputRef.current || !inputRef.current.select) {
      return;
    }

    inputRef.current.select();
    inputRef.current.setSelectionRange(0, 99999); // For mobile devices
    document.execCommand('copy');

    dispatch(enqueueNotification({
      key: `access_token_copy_${Date.now()}`,
      title: '',
      text: 'Access token has been copied to clipboard',
      variant: 'success',
    }));
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
    >
      <DialogTitle color="inherit">Access token</DialogTitle>
      <DialogContent className={styles.content}>
        {!!accessToken.length && (
          <div>
            <TextField
              inputRef={inputRef}
              label="Access token"
              defaultValue={accessToken}
              InputProps={{
                readOnly: true,
              }}
              multiline
              variant="outlined"
              fullWidth
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          Close
        </Button>
        <Button onClick={handleCopy} color="primary" autoFocus>
          Copy
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccessTokenDialog;
