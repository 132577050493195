import AppSettingsType from './AppSettingsType';

const AppSettings: AppSettingsType = {
  environment: 'Development',
  auth0: {
    domain: 'stingray-staging.eu.auth0.com',
    clientId: 'bXVgiqSuvfMUyAlyhCk6cnLZ0YuZVWjj',
    audience: 'https://stingrayapis.skywater.io/',
    scope: 'Read:EquipmentPlacementFleet Read:NodesDataStreams',
  },
  apis: {
    mobileProjectServer: {
      url: 'https://mobile-project-server.skywater.io/api/v1',
    },
  },
};

export default AppSettings;
