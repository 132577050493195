import { FleetFlatResponse } from 'api/mobileProjectServer/responseTypes';
import {
  CustomerFlat,
  LocationFlat,
  PlaceFlat,
  CableSpanFlat,
  FeedbargeCabinetFlat,
  PenCabinetFlat,
  PenCabinetPortFlat,
  NodeFlat,
} from './types';
import {
  customerFlatDefault,
  locationFlatDefault,
  placeFlatDefault,
  cableSpanFlatDefault,
  feedbargeCabinetFlatDefault,
  penCabinetFlatDefault,
  penCabinetPortFlatDefault,
  nodeFlatDefault,
} from './defaults';

const compare = (a: any, b: any) => {
  if (a.name > b.name) { return 1; }
  if (a.name.length > b.name.length) { return 1; }
  if (a.name < b.name) { return -1; }
  return 0;
};

/* eslint-disable import/prefer-default-export */
export const buildFleetFlatFromFlat = (fleet: FleetFlatResponse) => {
  const fleetCustomers: Array<CustomerFlat> = [];
  const fleetLocations: Array<LocationFlat> = [];
  const fleetPlaces: Array<PlaceFlat> = [];
  const fleetCableSpans: Array<CableSpanFlat> = [];
  const fleetFeedbargeCabinets: Array<FeedbargeCabinetFlat> = [];
  const fleetPenCabinets: Array<PenCabinetFlat> = [];
  const fleetPenCabinetPorts: Array<PenCabinetPortFlat> = [];
  const fleetNodes: Array<NodeFlat> = [];

  fleet.companies.forEach((_company) => {
    fleetCustomers.push({
      ...customerFlatDefault,
      code: _company.code,
      name: _company.name,
      hqAddress: _company.hqAddress,
      hqLatitude: _company.hqLatitude,
      hqLongitude: _company.hqLongitude,
      isActive: _company.isActive,
      createBy: _company.createBy,
      updateBy: _company.updateBy,
      createTMS: _company.createTMS,
      updateTMS: _company.updateTMS,
    });
  });
  fleet.locations.forEach((_location) => {
    fleetLocations.push({
      ...locationFlatDefault,
      code: _location.code,
      name: _location.name,
      isActive: _location.isActive,
      country: _location.country,
      aquaCultureSiteNumber: _location.aquacultureSiteNumber,
      latitude: _location.latitude,
      longitude: _location.longitude,
      customerCode: _location.companyCode,
      customerName: _location.companyName,
      customerIsActive: _location.companyIsActive,
      createBy: _location.createBy,
      updateBy: _location.updateBy,
      createTMS: _location.createTMS,
      updateTMS: _location.updateTMS,
    });
  });
  fleet.places.forEach((_place) => {
    fleetPlaces.push({
      ...placeFlatDefault,
      code: _place.code,
      name: _place.name,
      placeTypeId: _place.placeTypeId,
      placeTypeName: _place.placeTypeName,
      isActive: _place.isActive,
      customerCode: _place.companyCode,
      customerName: _place.companyName,
      customerIsActive: _place.companyIsActive,
      locationCode: _place.locationCode,
      locationName: _place.locationName,
      locationIsActive: _place.locationIsActive,
      createBy: _place.createBy,
      updateBy: _place.updateBy,
      createTMS: _place.createTMS,
      updateTMS: _place.updateTMS,
    });
  });
  fleet.cableSpans.forEach((_span) => {
    fleetCableSpans.push({
      ...cableSpanFlatDefault,
      code: _span.code,
      label: _span.label,
      customerCode: _span.companyCode,
      customerName: _span.companyName,
      customerIsActive: true,
      locationCode: _span.locationCode,
      locationName: _span.locationName,
      locationIsActive: true,
      placeCode: _span.placeCode,
      placeName: _span.placeName,
      placeTypeId: _span.placeTypeId,
      placeTypeName: _span.placeTypeName,
    });
  });
  fleet.feedbargeCabinets.forEach((_fbCab) => {
    fleetFeedbargeCabinets.push({
      ...feedbargeCabinetFlatDefault,
      serialNo: _fbCab.serialNo,
      customerCode: _fbCab.companyCode,
      customerName: _fbCab.companyName,
      customerIsActive: true,
      locationCode: _fbCab.locationCode,
      locationName: _fbCab.locationName,
      locationIsActive: true,
      placeCode: _fbCab.placeCode,
      placeName: _fbCab.placeName,
      placeTypeId: _fbCab.placeTypeId,
      placeTypeName: _fbCab.placeTypeName,
      fromTMS: _fbCab.fromTMS,
      createTMS: _fbCab.createTMS,
      updateTMS: _fbCab.updateTMS,
      createBy: _fbCab.createBy,
      updateBy: _fbCab.updateBy,
    });
  });
  fleet.penCabinets.forEach((_penCab) => {
    fleetPenCabinets.push({
      ...penCabinetFlatDefault,
      serialNo: _penCab.serialNo,
      customerCode: _penCab.companyCode,
      customerName: _penCab.companyName,
      customerIsActive: true,
      locationCode: _penCab.locationCode,
      locationName: _penCab.locationName,
      locationIsActive: true,
      placeCode: _penCab.placeCode,
      placeName: _penCab.placeName,
      placeTypeId: _penCab.placeTypeId,
      placeTypeName: _penCab.placeTypeName,
      fromTMS: _penCab.fromTMS,
      createTMS: _penCab.createTMS,
      updateTMS: _penCab.updateTMS,
      createBy: _penCab.createBy,
      updateBy: _penCab.updateBy,
    });
  });
  fleet.penCabinetPorts.forEach((_port) => {
    fleetPenCabinetPorts.push({
      ...penCabinetPortFlatDefault,
      cabinetSerialNo: _port.serialNo,
      port: _port.portNo,
      customerCode: _port.companyCode,
      customerName: _port.companyName,
      customerIsActive: true,
      locationCode: _port.locationCode,
      locationName: _port.locationName,
      locationIsActive: true,
      placeCode: _port.placeCode,
      placeName: _port.placeName,
      placeTypeId: _port.placeTypeId,
      placeTypeName: _port.placeTypeName,
      cableSpanCode: _port.cableSpanCode ? _port.cableSpanCode : null,
      cableSpanLabel: _port.cableSpanDescription,
      fromTMS: _port.fromTMS,
      createTMS: _port.createTMS,
      updateTMS: _port.updateTMS,
      createBy: _port.createBy,
      updateBy: _port.updateBy,
    });
  });
  fleet.nodes.forEach((_node) => {
    fleetNodes.push({
      ...nodeFlatDefault,
      serialNo: _node.serialNo,
      customerCode: _node.companyCode,
      customerName: _node.companyName,
      customerIsActive: true,
      locationCode: _node.locationCode,
      locationName: _node.locationName,
      locationIsActive: true,
      placeCode: _node.placeCode,
      placeName: _node.placeName,
      placeTypeId: _node.placeTypeId,
      placeTypeName: _node.placeTypeName,
      cableSpanCode: _node.cableSpanCode ? _node.cableSpanCode : null,
      cableSpanLabel: _node.cableSpanDescription,
      fromTMS: _node.fromTMS,
      createTMS: _node.createTMS,
      updateTMS: _node.updateTMS,
      createBy: _node.createBy,
      updateBy: _node.updateBy,
    });
  });

  return {
    customers: fleetCustomers.sort(compare),
    locations: fleetLocations.sort(compare),
    places: fleetPlaces.sort(),
    cableSpans: fleetCableSpans.sort(),
    feedbargeCabinets: fleetFeedbargeCabinets.sort(),
    penCabinets: fleetPenCabinets.sort(),
    penCabinetPorts: fleetPenCabinetPorts.sort(),
    nodes: fleetNodes.sort(),
  };
};
