import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import StingrayLogo from 'assets/stingray.png';
import UserMenu from './UserMenu';

import styles from './NavBar.module.css';

const NavBar = () => (
  <AppBar position="static">
    <Toolbar className={styles.toolbar}>
      <div className={styles.link}>
        <img className={styles.image} src={StingrayLogo} alt="Stingray branding" />
      </div>
      <Typography className={styles.title} variant="h6">Operations Mobile</Typography>
      <UserMenu />
    </Toolbar>
  </AppBar>
);

export default NavBar;
