export type FleetFlatResponse = {
  companies: Array<CustomerFlatResponse>,
  locations: Array<LocationFlatResponse>,
  places: Array<PlaceFlatResponse>,
  cableSpans: Array<CableSpanFlatResponse>,
  feedbargeCabinets: Array<FeedbargeCabinetFlatResponse>,
  penCabinets: Array<PenCabinetFlatResponse>,
  penCabinetPorts: Array<PenCabinetPortFlatResponse>,
  nodes: Array<NodeFlatResponse>,
};

export enum PlaceType {
  Pen = 1,
  Storage = 2,
  Feedbarge = 3,
  Service = 4,
  Other = 5,
  EndOfLife = 6,
}

export type CustomerFlatResponse = {
  code: number,
  name: string,
  hqAddress: string,
  hqLatitude: number | null,
  hqLongitude: number | null,
  isActive: boolean,
  createTMS: string,
  updateTMS: string,
  createBy: string,
  updateBy: string,
};
export type LocationFlatResponse = {
  code: number,
  name: string,
  country: string,
  aquacultureSiteNumber: string,
  latitude: string,
  longitude: string,
  isActive: boolean,
  companyCode: number,
  companyName: string,
  companyIsActive: boolean,
  createTMS: string,
  updateTMS: string,
  createBy: string,
  updateBy: string,
};
export type PlaceFlatResponse = {
  code: number,
  name: string,
  placeTypeId: PlaceType,
  placeTypeName: string,
  isActive: boolean,
  companyCode: number,
  companyName: string,
  companyIsActive: boolean,
  locationCode: number,
  locationName: string,
  locationIsActive: boolean,
  createTMS: string,
  updateTMS: string,
  createBy: string,
  updateBy: string,
};
export type CableSpanFlatResponse = {
  code: number,
  label: string,
  companyCode: number,
  companyName: string,
  locationCode: number,
  locationName: string,
  placeCode: number,
  placeName: string,
  placeTypeId: PlaceType,
  placeTypeName: string,
  fromTMS: string,
  toTMS: string | null,
  createTMS: string,
  updateTMS: string,
  createBy: string,
  updateBy: string,
};
export type FeedbargeCabinetFlatResponse = {
  serialNo: string,
  companyCode: number,
  companyName: string,
  locationCode: number,
  locationName: string,
  placeCode: number,
  placeName: string,
  placeTypeId: PlaceType,
  placeTypeName: string,
  fromTMS: string,
  toTMS: string | null,
  createTMS: string,
  updateTMS: string,
  createBy: string,
  updateBy: string,
};
export type PenCabinetFlatResponse = {
  serialNo: string,
  companyCode: number,
  companyName: string,
  locationCode: number,
  locationName: string,
  placeCode: number,
  placeName: string,
  placeTypeId: PlaceType,
  placeTypeName: string,
  fromTMS: string,
  toTMS: string | null,
  createTMS: string,
  updateTMS: string,
  createBy: string,
  updateBy: string,
};
export type PenCabinetPortFlatResponse = {
  serialNo: string,
  partNo: string,
  portNo: number,
  companyCode: number,
  companyName: string,
  locationCode: number,
  locationName: string,
  placeCode: number,
  placeName: string,
  placeTypeId: PlaceType,
  placeTypeName: string,
  cableSpanCode: number | null,
  cableSpanDescription: string,
  cableSpanPlaceCode: number | null,
  cableSpanPlaceName: string | null,
  fromTMS: string,
  toTMS: string | null,
  createTMS: string,
  updateTMS: string,
  createBy: string,
  updateBy: string,
};
export type NodeFlatResponse = {
  serialNo: string,
  partNo: string,
  companyCode: number,
  companyName: string,
  locationCode: number,
  locationName: string,
  placeCode: number,
  placeName: string,
  placeTypeId: PlaceType,
  placeTypeName: string,
  cableSpanCode: number | null,
  cableSpanDescription: string | null,
  fromTMS: string,
  toTMS: string | null,
  createTMS: string,
  updateTMS: string,
  createBy: string,
  updateBy: string,
};
