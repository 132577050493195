import React, { useState } from 'react';

import {
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';

import MoreVertIcon from '@material-ui/icons/MoreVert';

import AccessTokenDialog from './AccessTokenDialog/AccessTokenDialog';

const ProfileCardMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [accessTokenDialogOpen, setAccessTokenDialogOpen] = useState(false);

  const handleOpenAccessKeyDialog = () => {
    handleClose();
    setAccessTokenDialogOpen(true);
  };

  return (
    <div>
      <AccessTokenDialog open={accessTokenDialogOpen} onClose={() => setAccessTokenDialogOpen(false)} />
      <IconButton
        size="small"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOpenAccessKeyDialog}>
          Get access token
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ProfileCardMenu;
