import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  makeStyles,
  Button,
  LinearProgress,
} from '@material-ui/core';

import logo from 'assets/stingray.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
  },
  image: {
    marginBottom: theme.spacing(3),
  },
  loadingIndicator: {
    width: 160,
  },
}));

const SignIn = () => {
  const classes = useStyles();

  const { isLoading, loginWithPopup } = useAuth0();

  return (
    <div className={classes.root}>
      <img className={classes.image} src={logo} alt="stingray logo" />
      {
        isLoading
          ? <LinearProgress className={classes.loadingIndicator} />
          : <Button variant="contained" color="primary" onClick={loginWithPopup}>Sign In</Button>
      }

    </div>
  );
};

export default SignIn;
