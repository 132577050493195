import { FleetFlat, LocationFlat } from 'store/mobileProjectServer/types';
import { Location } from '../../types/select.type';

const NearLocations = {
  toRadian: (degree: number):number => (degree * Math.PI) / 180,
  calculateDistance: (origin:number[], destination:number[]) => {
    const { toRadian } = NearLocations;
    if (origin[0] !== null && origin[1] !== null && destination[0] !== null && destination[1] !== null) {
      const lon1:number = toRadian(Number(origin[1]));
      const lat1:number = toRadian(Number(origin[0]));
      const lon2:number = toRadian(Number(destination[1]));
      const lat2:number = toRadian(Number(destination[0]));
      const deltaLat:number = lat2 - lat1;
      const deltaLon:number = lon2 - lon1;
      const a:number = (Math.sin(deltaLat / 2) ** 2)
      + Math.cos(lat1) * Math.cos(lat2) * (Math.sin(deltaLon / 2) ** 2);
      const c:number = 2 * Math.asin(Math.sqrt(a));
      const EARTH_RADIUS:number = 6371;
      return c * EARTH_RADIUS * 1000;
    }
    return 0;
  },
};
const getNearLocations = (fleetFlat:FleetFlat, myPosition:Location):LocationFlat[] => {
  const { calculateDistance }:any = NearLocations;
  const tempNearLocations:LocationFlat[] = [...fleetFlat.locations];
  const distance:any[] = [];
  for (let i:number = 0; i < tempNearLocations.length; i += 1) {
    if (tempNearLocations[i].latitude !== null && tempNearLocations[i].longitude !== null) {
      distance[i] = calculateDistance([tempNearLocations[i].latitude,
        tempNearLocations[i].longitude], [myPosition.lat, myPosition.lng]);
    } else distance[i] = 1000000000000000;
  }
  tempNearLocations.sort((a:any, b:any) => distance[tempNearLocations.indexOf(a)] - distance[tempNearLocations.indexOf(b)]);
  return tempNearLocations;
};

export default getNearLocations;
