/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Card, Box, makeStyles, IconButton,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useAuth0 } from '@auth0/auth0-react';
import { Node } from 'components/types/node.types';
import Ticket from './Ticket';

// Storage:
import { selectCustomerNodeCode } from '../../../store/mobileProjectServer/mobileProjectServerSlice';
import { getNodeStatus } from '../../../api/mobileProjectServer/api';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    marginTop: '5px',
    marginRight: '5px',
  },
  header: {
    color: '#22B37E',
    display: 'flex',
    borderBottom: '6px solid rgba(74,72,52,0.72)',
    borderRadius: '0px 0px 11px 10px',
    '& div': {
      textAlign: 'center',
      fontSize: 'larger',
      width: '100%',
      margin: '0 5px 0 -12%',
    },
    '& h3': {
      margin: '0 30% 0 30%',
      color: 'white',
    },
  },
  location: {
    margin: '10px 0 10px -10px',
    gridTemplateRows: 'repeat(1fr)',
  },
  pos: {
    marginBottom: 12,
  },
  content: {
    border: '3px outset steelblue',
    marginLeft: '-10px',
    padding: '5px',
  },
  registerBtn: {
    backgroundColor: 'steelblue',
    color: 'white',
  },
}));

const fieldValue = {
  header: 'Node Status',
  cardNotAvailable: 'Data is not available for the node at the moment',
  previousPage: 'Click here to return to previous page.',
  buOnline: 'BU Online:',
  vertical: 'Vertical:',
  horizontal: 'Horizontal:',
  suOnline: 'SU Online',
  passing5min: 'Passing / 5mins',
  passing60min: 'Passing / 60mins',
  shots5mins: 'Shots / 5mins',
  shots60mins: 'Shots / 60mins',
  tickets: 'Tickets:',
};

const NodePage = () => {
  const classes = useStyles();
  const history = useHistory();
  const [nodeStatus, setNodeStatus] = useState<any>({});
  const nodeId: any = useSelector(selectCustomerNodeCode);
  const { getAccessTokenSilently } = useAuth0();

  const gotoLocationPage = () => {
    history.goBack();
  };

  const getStatus = async () => {
    const response = getNodeStatus(
      String(nodeId),
      await getAccessTokenSilently(),
    );
    return response;
  };

  useEffect(() => {
    getStatus().then((res: Node | {}) => {
      setNodeStatus(res);
      if (res === {}) {
        gotoLocationPage();
      }
    });
  }, [getAccessTokenSilently, nodeId]);

  return (
    <div>
      <div className={classes.root}>
        <div className={classes.header} role="button" tabIndex={-1} onKeyPress={gotoLocationPage} onClick={gotoLocationPage}>
          <IconButton
            size="small"
            aria-label="back"
            aria-controls="long-menu"
            aria-haspopup="true"
          >
            <ArrowBackIcon />
          </IconButton>
          <h3>{nodeStatus.buInfo ? nodeStatus.buInfo.id.slice(3) : ''}</h3>
        </div>
        <div>
          <Box fontSize={18} m={1}>{fieldValue.header}</Box>
        </div>
        {nodeStatus.buInfo === undefined
          ? (
            <Card variant="outlined" style={{ padding: '5px', marginTop: '5px' }}>
              {fieldValue.cardNotAvailable}
              {' '}
              <a href="/location" style={{ color: 'gray' }}>{fieldValue.previousPage}</a>
            </Card>
          ) : (
            <Card variant="outlined" style={{ padding: '5px', marginTop: '5px' }}>
              <div color="textSecondary">
                <Box fontSize={13} m={1}>
                  {fieldValue.buOnline}
                  {nodeStatus.buInfo
                    ? nodeStatus.buInfo.isOffline
                      ? 'No'
                      : 'Yes'
                    : 'loading..'}
                </Box>

              </div>
              <div>
                <Box fontSize={13} m={1}>
                  {fieldValue.horizontal}
                  {' '}
                  {nodeStatus.buInfo
                    ? nodeStatus.buInfo.sensors
                      ? nodeStatus.buInfo.sensors.horizontalPosition
                      : 'unkown'
                    : 'unkown'}
                </Box>
              </div>
              <div>
                <Box fontSize={13} m={1}>
                  {fieldValue.vertical}
                  {nodeStatus.buInfo
                    ? nodeStatus.buInfo.sensors
                      ? nodeStatus.buInfo.sensors.verticalPosition
                      : 'unkown'
                    : 'unkown'}
                </Box>
              </div>
              <div color="textSecondary">
                <Box fontSize={13} m={1}>
                  {fieldValue.suOnline}
                  {nodeStatus.suInfo
                    ? nodeStatus.suInfo.isOffline
                      ? 'No'
                      : 'Yes'
                    : 'loading..'}
                </Box>
              </div>
              <div>
                <Box fontSize={13} m={1}>
                  {fieldValue.passing5min}
                  {nodeStatus.suInfo
                    ? nodeStatus.suInfo.sensors
                      ? nodeStatus.suInfo.sensors.passingsLast5Mins
                      : 'unkown'
                    : 'unkown'}
                </Box>

              </div>
              <div>
                <Box fontSize={13} m={1}>
                  {fieldValue.passing60min}
                  {nodeStatus.suInfo
                    ? nodeStatus.suInfo.sensors
                      ? nodeStatus.suInfo.sensors.passingsLast60Mins
                      : 'unkown'
                    : 'unkown'}
                </Box>
              </div>
              <div>
                <Box fontSize={13} m={1}>
                  {fieldValue.shots5mins}
                  {' '}
                  {nodeStatus.suInfo
                    ? nodeStatus.suInfo.sensors
                      ? nodeStatus.suInfo.sensors.shotsLast5Mins
                      : 'unkown'
                    : 'unkown'}
                </Box>

              </div>
              <div>
                <Box fontSize={13} m={1}>
                  {fieldValue.shots60mins}
                  {' '}
                  {nodeStatus.suInfo
                    ? nodeStatus.suInfo.sensors
                      ? nodeStatus.suInfo.sensors.shotsLast60Mins
                      : 'unkown'
                    : 'unkown'}
                </Box>
              </div>
              <IconButton
                size="small"
                aria-label="back"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={() => getStatus().then((res: Node | {}) => setNodeStatus(res))}
              >
                <RefreshIcon />
              </IconButton>
            </Card>
          )}
      </div>
      {nodeStatus.buInfo !== undefined
        ? (
          <div>
            <div>
              <Box fontSize={18} m={1}>{fieldValue.tickets}</Box>
            </div>
            <Ticket nodeId={nodeId} accessToken={getAccessTokenSilently()} />
          </div>
        ) : <div />}
    </div>
  );
};

export default NodePage;
