import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import {
  Button,
  Avatar,
  Divider,
} from '@material-ui/core';

import ProfileCardMenu from './ProfileCardMenu/ProfileCardMenu';

import styles from './ProfileCard.module.css';

const ProfileCard = () => {
  const { user, logout } = useAuth0();
  const { name, email, picture }:any = user;

  return (
    <div className={styles.container}>
      <div className={styles.menuWrapper}>
        <ProfileCardMenu />
      </div>
      <div className={styles.content}>
        <Avatar alt={name} src={picture} className={styles.avatar} />
        <div className={styles.info}>
          <span className={styles.name}>{name}</span>
          <span className={styles.email}>{email}</span>
        </div>
      </div>
      <Divider className={styles.divider} />
      <div className={styles.actions}>
        <Button
          variant="text"
          onClick={() => logout()}
          size="small"
          className={styles.signOut}
        >
          Sign out
        </Button>
      </div>
    </div>
  );
};

export default React.memo(ProfileCard);
