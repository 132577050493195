import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Typography } from '@material-ui/core';
import { selectNotifications, removeNotification } from 'store/notifications/notificationsSlice';

const useStyles = makeStyles(() => ({
  notification: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 300,
  },
  notificationText: {
    whiteSpace: 'pre-line',
  },
}));

const Notifier = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [displayed, setDisplayed] = useState<Array<string>>([]);

  const storeDisplayed = (key: string) => setDisplayed((p) => [...p, key]);
  const removeDisplayed = (key: string) => setDisplayed((p) => p.filter((i) => i !== key));

  useEffect(() => {
    notifications.forEach(({
      key, text, variant, title,
    }) => {
      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(
        <div className={classes.notification}>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body1" className={classes.notificationText}>{text}</Typography>
        </div>,
        {
          key,
          variant,
          onExited: (event, myKey) => {
            if (typeof myKey === 'string') {
              dispatch(removeNotification(myKey));
              removeDisplayed(myKey);
            }
          },
        },
      );

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [
    notifications, closeSnackbar, enqueueSnackbar, dispatch,
    displayed, classes.notification, classes.notificationText,
  ]);

  return <div />;
};

export default Notifier;
