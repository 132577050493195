import React from 'react';
import {
  Container,
  Typography,
} from '@material-ui/core';

import styles from './NotFound.module.css';

const NotFound = () => (
  <Container maxWidth="xl" className={styles.content}>
    <Typography variant="h1">
      Page Not Found :(
    </Typography>
  </Container>
);

export default NotFound;
