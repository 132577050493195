import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import {
  selectFleetFlat,
  setCustomerLocationCode,
  setCustomerId,
} from 'store/mobileProjectServer/mobileProjectServerSlice';
import {
  FleetFlat,
  LocationFlat,
  CustomerFlat,
} from 'store/mobileProjectServer/types';
import { Search } from '../../types/select.type';

const useStyles = makeStyles(() => ({
  textfield: {
    margin: 1,
    width: '20%',
    marginLeft: '5px',
    minWidth: 120,
  },
}));

const SearchBar = () => {
  const classes = useStyles();
  const fleetFlat: FleetFlat = useSelector(selectFleetFlat);
  const dispatch = useDispatch();

  const getSearchList = (): Search[] => {
    const searchList: Search[] = [];
    fleetFlat.customers.map((customer: CustomerFlat): any => searchList.push({
      searchText: customer.name,
      key: customer.code,
      type: 'customer',
    }));

    fleetFlat.locations.map((location: LocationFlat): any => searchList.push({
      searchText: location.name,
      key: location.code,
      type: 'location',
    }));
    return searchList;
  };

  const handleChange = (value:string | null) => {
    const searchValue: Search | undefined = getSearchList().find(
      (el) => el.searchText === value,
    );

    if (searchValue !== undefined) {
      if (searchValue.type === 'location') {
        dispatch(setCustomerLocationCode(Number(searchValue.key)));
        dispatch(setCustomerId(fleetFlat.locations.filter((loc) => loc.name === searchValue.searchText)[0].customerCode));
      } else dispatch(setCustomerId(Number(searchValue.key)));
    }
  };
  return (
    <div className={classes.textfield}>
      <Autocomplete
        freeSolo
        id="searchbar"
        disableClearable
        options={getSearchList().map(
          (option: Search) => option.searchText,
        )}
        onChange={(event: any, newValue: string | null) => handleChange(newValue)}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            label="Search"
            margin="normal"
            InputProps={{ ...params.InputProps, type: 'search' }}
            onChange={({ target }) => handleChange(target.value)}
          />
        )}
      />
    </div>
  );
};

export default SearchBar;
