import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import {
  Grow,
  Popper,
  ClickAwayListener,
  Paper,
  Avatar,
  Button,
} from '@material-ui/core';

import ProfileCard from './ProfileCard/ProfileCard';

import styles from './MenuContainer.module.css';

const MenuContainer = () => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const { user } = useAuth0<{ picture?: undefined | string, name?: undefined | string }>();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        size="small"
        className={styles.button}
        ref={anchorRef}
        aria-owns={open ? 'apps-menu-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="inherit"
      >
        { (user?.picture !== undefined && user?.picture !== undefined)
        && <Avatar src={user.picture} alt={user.name} className={styles.avatar} />}
      </Button>

      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal placement="top-end">
        {({ TransitionProps }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Grow {...TransitionProps}>
            <Paper id="apps-menu-grow">
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <ProfileCard />
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default React.memo(MenuContainer);
