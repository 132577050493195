import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Auth0Provider } from '@auth0/auth0-react';
import { SnackbarProvider } from 'notistack';
import AppSettings from 'AppSettings';
import theme from './theme';
import App from './App';
import store from './store/store';

const persistor = persistStore(store);

ReactDOM.render(
  <div>
    <Auth0Provider
      domain={AppSettings.auth0.domain}
      clientId={AppSettings.auth0.clientId}
      audience={AppSettings.auth0.audience}
      scope={AppSettings.auth0.scope}
      redirectUri={window.location.origin}
    >
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <SnackbarProvider
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <CssBaseline />
                <App />
              </SnackbarProvider>
            </PersistGate>
          </Provider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Auth0Provider>
  </div>,
  document.getElementById('root'),
);
