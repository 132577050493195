/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';

import {
  Button, Modal, Fade, Backdrop, IconButton,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Storage:
import { Ticket, NodeInfo } from 'components/types/node.types';
import { getTicketStatus } from '../../../api/mobileProjectServer/api';
import NewTicketRegister from './RegisterTicket';
import StatusCard from './StatusCard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    marginTop: '5px',
    marginLeft: '5px',
    marginRight: '5px',
  },
  header: {
    color: '#22B37E',
    display: 'flex',
    borderBottom: '6px solid rgba(74,72,52,0.72)',
    borderRadius: '0px 0px 11px 10px',
    margin: '0 0 0 -18px',
    '& img': {
      marginBottom: '5px',
      width: '30px',
      height: '30px',
    },
    '& div': {
      textAlign: 'center',
      fontSize: 'larger',
      width: '100%',
      margin: '0 5px 0 -12%',
    },
    '& h3': {
      margin: '0 80% 0 80%',
      color: 'white',
    },
  },
  location: {
    margin: '10px 0 10px -10px',
    gridTemplateRows: 'repeat(1fr)',
  },
  pos: {
    marginBottom: 12,
  },
  content: {
    border: '3px outset steelblue',
    borderRadius: '10px',
    marginLeft: '-10px',
    padding: '5px',
  },
  registerBtn: {
    textAlign: 'center',
    maxWidth: '450px',
  },
  registration: {
    textAlign: 'center',
  },
  registerForm: {
    border: '3px outset #steelblue',
    borderRadius: '15px',
  },
  ticketStatus: {
    width: '100%',
    display: 'grid',
    marginBottom: '-10px',
    backgroundColor: theme.palette.background.paper,
    gridTemplateColumns: '30% 10%',
    gridColumnGap: '55%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const NodeTicket = (nodeInfo: NodeInfo) => {
  const [tickets, setTickets] = useState<Ticket []>([]);
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [registerNewTicket, setRegisterNewTicket] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    const getTickets = () => nodeInfo.accessToken.then((token:string) => {
      setAccessToken(token);
      getTicketStatus(token).then((res) => {
        setTickets(res.filter((ticket:Ticket) => ticket.equipmentSerialNo === nodeInfo.nodeId));
      });
    });
    getTickets();
  }, [nodeInfo.accessToken, nodeInfo.nodeId]);

  return (
    <div className={classes.registration}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => { setRegisterNewTicket(!registerNewTicket); }}
        className={classes.registerBtn}
        style={{ width: '80%', marginBottom: '3%' }}
      >
        {!registerNewTicket ? 'Register a new ticket' : 'Registering a ticket'}
      </Button>
      <div style={{ margin: '5px 0px 5px 0px', width: '100%', height: '100%' }}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-Description"
          className={classes.modal}
          open={registerNewTicket}
          onClose={() => setRegisterNewTicket(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={registerNewTicket} style={{ maxWidth: '450px', width: '100%', height: '100%' }}>
            <div className={classes.paper}>
              <div
                className={classes.header}
                role="button"
                tabIndex={-2}
                onClick={() => setRegisterNewTicket(false)}
                onKeyPress={() => setRegisterNewTicket(false)}
              >
                <IconButton
                  size="small"
                  aria-label="back"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                >
                  <ArrowBackIcon />
                </IconButton>
                <h3 style={{ margin: '0 0 0 30%' }}>{nodeInfo.nodeId}</h3>
              </div>
              { registerNewTicket && <NewTicketRegister nodeInfo={nodeInfo} setRegister={setRegisterNewTicket} />}
            </div>
          </Fade>
        </Modal>
      </div>
      <div>
        <div>
          {tickets.map((ticket:Ticket) => <StatusCard key={ticket.id} ticket={ticket} accessToken={accessToken} />)}
        </div>
      </div>
    </div>
  );
};

export default NodeTicket;
