/* eslint-disable react-hooks/exhaustive-deps */import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { GridList, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useAuth0 } from '@auth0/auth0-react';

import { FleetFlat, NodeFlat } from 'store/mobileProjectServer/types';
import { Ticket } from 'components/types/node.types';
import NodeList from './NodeList';

import {
  selectCustomerId,
  selectCustomerLocationCode,
  selectFleetFlat,
} from '../../../store/mobileProjectServer/mobileProjectServerSlice';

import { getTicketStatus } from '../../../api/mobileProjectServer/api';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    marginTop: '5px',
    marginLeft: '5px',
    marginRight: '5px',
  },
  header: {
    color: '#22B37E',
    display: 'flex',
    borderBottom: '6px solid rgba(74,72,52,0.72)',
    borderRadius: '0px 0px 11px 10px',
    margin: '0 0 0 -18px',
    '& div': {
      textAlign: 'center',
      fontSize: 'larger',
      width: '100%',
      margin: '0 5px 0 -12%',
    },
  },
  location: {
    margin: '10px 0 10px -10px',
    gridTemplateRows: 'repeat(1fr)',
  },
  node: {
    borderRadius: '12px',
    margin: '0 0 6px',
    width: '100%',
    boxShadow: '0 5px 8px 0 rgba(0, 0, 0, 0.3)',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

const LocationPage = () => {
  const fleetFlat: FleetFlat = useSelector(selectFleetFlat);
  const customerId: number | null = useSelector(selectCustomerId);
  const locationId: number | null = useSelector(selectCustomerLocationCode);
  const [locationNodes, setLocationNodes] = useState({});
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [authToken, setAuthToken] = useState('');
  const { getAccessTokenSilently } = useAuth0();

  const classes = useStyles();
  const history = useHistory();

  const gotoSelectPage = () => {
    history.goBack();
  };

  const getCustomerName = (): string => {
    let customerName: string | undefined = '';
    if (locationId !== null) {
      customerName = fleetFlat.locations.find(
        (location) => location.code === locationId,
      )?.customerName;
    } else {
      customerName = fleetFlat.customers.find(
        (customer) => customer.code === customerId,
      )?.name;
    }
    return customerName || '';
  };

  const getTickets = (token: string) => {
    const response = getTicketStatus(token);
    return response;
  };

  const getAvailableNodes = () => {
    const visibleNodes: NodeFlat[] = [];
    let groupNodes: NodeFlat[] = [];

    if (locationId) {
      fleetFlat.nodes.filter((node: NodeFlat): boolean => {
        if (node.locationCode === locationId) visibleNodes.push(node);
        return true;
      });
    } else if (customerId) {
      fleetFlat.nodes.filter((node: NodeFlat): boolean => {
        if (node.customerCode === customerId) visibleNodes.push(node);
        return true;
      });
    }
    groupNodes = visibleNodes.reduce((r: any, a: any) => {
      // eslint-disable-next-line no-param-reassign
      r[a.locationName] = r[a.locationName] || [];
      r[a.locationName].push(a);
      return r;
    }, Object.create(null));
    setLocationNodes(groupNodes);
  };

  useEffect(() => {
    getAvailableNodes();
    getAccessTokenSilently().then((token) => {
      setAuthToken(token);
      getTickets(token).then((Tickets: Ticket[]) => {
        setTickets(Tickets);
      });
    });
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.header} role="button" tabIndex={-42} onKeyPress={gotoSelectPage} onClick={gotoSelectPage}>
        <IconButton
          size="small"
          aria-label="back"
          aria-controls="long-menu"
          aria-haspopup="true"
        >
          <ArrowBackIcon />
        </IconButton>
        <div>Location page</div>
      </div>
      <div className={classes.location}>
        <GridList cols={1} style={{ width: '100%' }}>
          {authToken !== ''
            && Object.keys(locationNodes).map((key, index) => (
              <NodeList
                key={key}
                customer={getCustomerName()}
                tickets={tickets}
                location={Object.keys(locationNodes)[index]}
                locationNodes={Object.values(locationNodes)[index]}
                authToken={authToken}
              />
            ))}
        </GridList>
      </div>
    </div>
  );
};

export default LocationPage;
