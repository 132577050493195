/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Card, CardContent, IconButton, Typography, Modal, Fade, Backdrop,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import ImageViewer from 'react-simple-image-viewer';
import { NodeStatusCard, Ticket } from 'components/types/node.types';
import { getTicketStatusById, getAttachmentById, toggleTicketStatus } from '../../../api/mobileProjectServer/api';

const useStyles = makeStyles((theme) => createStyles({
  root: {
    width: '100%',
    marginBottom: '4px',
    paddingRight: '8px',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    color: '#22B37E',
    display: 'flex',
    borderBottom: '6px solid rgba(74,72,52,0.72)',
    borderRadius: '0px 0px 11px 10px',
    margin: '0 0 0 -18px',
    '& div': {
      textAlign: 'center',
      fontSize: 'larger',
      width: '100%',
      margin: '0 5px 0 -12%',
    },
    '& h3': {
      margin: '0 80% 0 80%',
      color: 'white',
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  ticketStatus: {
    display: 'grid',
    marginBottom: '5px',
    backgroundColor: theme.palette.background.paper,
    gridTemplateColumns: '30% 10%',
    gridColumnGap: '55%',
  },
  summary: {
    textAlign: 'left',
    fontSize: 'larger',
    margin: '10px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  imgContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '5px',
    justifyContent: 'space-evenly',
  },
  img: {
    display: 'block',
    width: 'auto',
    float: 'right',
    height: 'auto',
  },
}));

const TicketImages = (props:any) => {
  const { accessToken, id } = props;
  const [images, setImages] = useState<any[]>([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const classes = useStyles();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getImages = () => {
    getAttachmentById(accessToken, id).then((imgSrc:string) => {
      setImages([...images, imgSrc]);
    });
  };
  useEffect(() => {
    getImages();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openImageViewer = useCallback((index:number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div>
      {images !== undefined && images.map((imgSrc:string, index:number) => (
        <div className={classes.img} key={index}>
          <br />
          <img
            src={imgSrc}
            onClick={() => openImageViewer(index)}
            key={index}
            style={{ width: '70px', height: '50px' }}
            alt=""
          />
        </div>
      ))}
      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
        />
      )}
    </div>
  );
};

const StatusCard = (props: NodeStatusCard) => {
  const { ticket, accessToken } = props;
  const classes = useStyles();
  const [modalOpened, setModal] = useState(false);
  const [ticketDetails, setTicketDetails] = useState<any>({});
  const [isTicketOpen, setTicketStatus] = useState<Boolean>(false);
  const createdDate = new Date(ticket.createTMS);
  const getTickets = () => {
    getTicketStatusById(accessToken, ticket.id).then((res) => {
      setTicketDetails(res);
      setTicketStatus(() => Boolean(res.isOpen));
    });
  };

  const handleStatusCardClick = () => {
    setModal(!modalOpened);
    if (!modalOpened) {
      getTickets();
    }
  };

  const handleToggleClick = () => {
    const data = { isOpen: !ticketDetails.isOpen };
    setTicketStatus(() => !isTicketOpen);
    toggleTicketStatus(accessToken, data, ticketDetails.id);
  };

  useEffect(() => {
    getTickets();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root} role="button">
      <Card
        className={classes.ticketStatus}
        tabIndex={-42}
        onKeyPress={() => { setModal(!modalOpened); handleStatusCardClick(); }}
        onClick={() => {
          setModal(!modalOpened); handleStatusCardClick();
        }}
      >
        <div className={classes.summary}>{ticket.summary}</div>
        <div>
          <p style={{ margin: '5px 0px 0px -25px' }}>
            {`${createdDate.getDate()}/${createdDate.getMonth()}/${createdDate.getFullYear()}`}
          </p>
          {isTicketOpen ? <p style={{ position: 'relative', float: 'right', color: 'red' }}>OPEN</p>
            : <p style={{ position: 'relative', float: 'right', color: 'green' }}>CLOSED</p>}
          <div style={{
            display: 'flex', position: 'relative', float: 'right', textAlign: 'right', marginTop: '-20px',
          }}
          >
            {' '}
            <p>Attachments:</p>
            &nbsp;
            <p>{ticketDetails.attachments !== undefined && ticketDetails.attachments.length}</p>
          </div>
        </div>
      </Card>
      { modalOpened
      && (
      <div style={{ marginTop: '-25px' }}>
        <div style={{ margin: '5px 0px 5px 0px', width: '100%', height: '100%' }}>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-Description"
            className={classes.modal}
            open={modalOpened}
            onClose={() => setModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
          >
            <Fade in={modalOpened} style={{ maxWidth: '450px', width: '100%', height: '100%' }}>
              <div className={classes.paper}>
                <div
                  className={classes.header}
                  role="button"
                  tabIndex={-2}
                  onClick={() => setModal(false)}
                  onKeyPress={() => setModal(false)}
                >
                  <IconButton
                    size="small"
                    aria-label="back"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  <div>
                    {' '}
                    Node:
                    {ticketDetails.equipmentSerialNo}
                  </div>
                </div>
                <div>
                  <Card>
                    <CardContent>
                      <Typography
                        color="textSecondary"
                        gutterBottom
                      >
                        Ticket info:
                        {' '}
                        <br />
                        Id :
                        {' '}
                        {ticketDetails.id}
                        <br />
                        Summary:
                        {' '}
                        {ticketDetails.summary}
                        {' '}
                        <br />
                        More details:
                        {' '}
                        {ticketDetails.description}
                        {' '}
                        <br />
                        Photos:
                        {' '}
                        <br />
                      </Typography>
                      <div style={{ marginLeft: '10px', display: 'flex' }}>
                        {ticketDetails.attachments.map((details:Ticket, index:number) => (
                          <TicketImages key={index} accessToken={accessToken} id={details.id} />
                        ))}
                      </div>
                      <IconButton
                        onClick={handleToggleClick}
                        aria-label="toggle"
                      >
                        {!isTicketOpen ? (
                          <Typography
                            color="textSecondary"
                            gutterBottom
                          >
                            Open ticket
                            <CheckIcon />
                          </Typography>
                        ) : (
                          <Typography
                            color="textSecondary"
                            gutterBottom
                          >
                            Close ticket
                            <CloseIcon />
                          </Typography>
                        )}
                      </IconButton>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </Fade>
          </Modal>
        </div>
      </div>
      )}
    </div>
  );
};

export default StatusCard;
